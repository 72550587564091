export const LOCALE = process.env.NEXT_PUBLIC_LOCALE;
export const CURRENCY = process.env.NEXT_PUBLIC_CURRENCY;
export const CURRENCY_SYMBOL = process.env.NEXT_PUBLIC_CURRENCY_SYMBOL;
export const ESPOTS = process.env.NEXT_PUBLIC_ESPOTS;
export const QUERY_MOBILE = '(max-width: 760px)';
export const PREPAGO = 'PREPAGO';
export const OTRO = 'OTRO';
export const POSPAGO = 'POSPAGO';
export const PLAN = 'PLAN';
export const TYPE_PREPAGO = '1';
export const TYPE_PLAN = '2';
export const TYPE_EQUIPO = '4';
export const TYPE_ACCESORIO = '5';
export const TYPE_POSPAGO = '6';
export const APPROVED = 'APPROVED';
